import MarkdownText from '../../MarkdownText/MarkdownText'
import CommunityButtons from '../CommunityButtons/CommunityButtons'
import './style.scss'

interface ModalProps {
  setIsOpenModal: (value: boolean) => void
  title: string
  code: string
  paper?: string
  description: string
}

const CommunityModal = ({
  setIsOpenModal,
  title,
  code,
  paper,
  description,
}: ModalProps) => {
  return (
    <div className="CommunityModal" data-testid="community-modal">
      <div className="CommunityModal__header">
        <h5>{title}</h5>
        <button
          onClick={() => setIsOpenModal(false)}
          aria-label="Close modal"
          data-testid="close-modal"
        >
          <i className="bx bx-x text-xl"></i>
        </button>
      </div>

      <MarkdownText
        text={description}
        className="CommunityModal__description"
      />

      <div className="CommunityCard__buttons">
        <CommunityButtons paper={paper} code={code} addClass="mr-1" />
      </div>
    </div>
  )
}

export default CommunityModal
